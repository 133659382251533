<template>
	<div class="random-draw">
		<div class="title">
		    <h2>START领前列·竞巅峰-2022贝坦利优秀病例大赛</h2>
		    <i>全国总决赛</i>
		</div>
		<div class="main">
			<div class="box">
				<div class="player" v-for="player in playerList" v-if="playerList.length>0">
					<img :src="player.img">
					<div class="name">{{player.name}}</div>
					<div class="hospital-name">{{player.hospital_name}}</div>
					<div class="order">序号{{player.order}}</div>
				</div>
				<div class="playerNo" v-for="playerNo in playerNoList" v-if="playerList.length<1">
					<div class="img"></div>
				</div>
			</div>
			<div class="button" @click="randomDraw">抽签</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				playerList:[],
				playerNoList:[],
				timer:null
			}
		},
		created() {
			console.log(this.playerNoList)
			for (var i=0;i<12;i++){
				this.playerNoList.push({
					name:'XXX',
					img:'https://image.ysxxlm.cn/ysxxlm/Uploads/20221013/26fe22212efc0022739c1f88a17ccb46.jpg',
					hospital_name:'XXX',
					order:'XXX'
				})
			}
			console.log(this.playerNoList)
		},
		mounted() {
			this.startMachine()

		},
		methods: {
			startMachine(){
				this.timer = setInterval(() => {
					let itemList=document.querySelectorAll('.img')
					let index1=Math.floor (Math.random()*itemList.length+1)
					let index2=Math.floor (Math.random()*itemList.length+1)
					let index3=Math.floor (Math.random()*itemList.length+1)
					let index4=Math.floor (Math.random()*itemList.length+1)
					let index5=Math.floor (Math.random()*itemList.length+1)
					
					itemList.forEach(item=>{item.classList.remove('active')})
					itemList[index1].classList.add('active')
					itemList[index2].classList.add('active')
					itemList[index3].classList.add('active')
					itemList[index4].classList.add('active')
					itemList[index5].classList.add('active')
				}, 200);
			},
			randomDraw(index){
				this.$axios.post(this.apiUrl.playerDraw,{group_id:this.$route.query.group_id}).then(res => {
					console.log(res)
					if(res.code==1){
						this.playerList = res.data
						this.$message({message: res.msg,type: 'success'})
					}
				})
			}
		},
		destroyed(){
			clearInterval(this.timer)
		}
	}
</script>

<style scoped lang="scss">
	$color1:#FDEC00;
	.random-draw{
		background: url("~@/assets/img/by2022-9.jpg") no-repeat;
		background-size: 100% 100%;
		height: 100vh;
		overflow: auto;
		.title{
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			font-weight: bold;
			font-family:SimHei;
			color: $color1;
			margin-top: 50px;
			h2{font-size: 50px;line-height: 60px}
			i{font-size: 45px;font-family:'楷体'; font-style:normal;line-height: 55px}
		}
		.main{
			width: 1317px;
			margin: 10px auto 20px;
			.box{
				flex: 1;
				display: flex;
				flex-wrap: wrap;
				position: relative;
				overflow: hidden;
				.player, .playerNo{
					width: 300px;
					height: 230px;
					color:#fff;
					>img{
						width: 100px;
						height: 100px;
						border-radius: 50%;
						display: block;
						margin: 0 auto;
					}
					.img{
						width: 100px;height: 100px;background: #46A3EF;border-radius: 50%;margin: 0 auto;
					}
					.active{
						background: $color1;
					}
					>div{text-align: center}
					.name{font-size: 18px}
					.hospital-name, .order{font-size: 12px}
				}
			}
			.button{
				margin: 0 auto;
				width: 124px;
				height: 38px;
				line-height: 38px;
				background: #088EE9;
				border-radius: 16px;
				font-size: 25px;
				font-weight: bold;
				text-align: center;
				color: $color1;
				cursor:pointer;
			}
		}
	}
</style>